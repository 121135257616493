<template>
  <div class="pt-12 min-h-screen">
    <div>
      <ProductListingBreadcrumbs :breadcrumbs="model.breadcrumbs" class="h-24" />
      <div class="layout-container mb-24">
        <h1 v-if="model.pageTitle" class="mt-24 md:mt-40">{{ model.pageTitle }}</h1>
        <div
          class="page-text lg:w-1/2"
          :class="{
            'page-text--hide-overflow': hidePageTextOverflow,
            'page-text--show': showPageText,
          }"
        >
          <div ref="pageText">
            <WysiwygWrapper
              v-if="model.mainBody"
              :style-config="{
                'wysiwyg wysiwyg--sm-text inline-block': true,
              }"
              :html="model.mainBody"
            />
            <div
              v-if="hidePageTextOverflow && showToggleText && model.mainBody"
              class="absolute bottom-0 sm:bottom-6 left-0 transform z-10 w-auto cursor-pointer transition-all duration-200 ease-in-out text-xsmall-medium sm:text-small-medium"
              @click="showPageText = !showPageText"
            >
              <span v-if="!showPageText">
                {{ sharedResources.showMore }}
              </span>
              <span v-else>
                {{ sharedResources.showLess }}
              </span>
              <ChevronIcon
                class="h-16 w-16 ml-8 inline-block transform transition-all duration-300 ease-in-out"
                :class="{
                  'rotate-x-180': showPageText,
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="layout-container px-0 sm:px-24 md:px-56"
      >
        <div
          v-if="tags && tags.length"
          class="text-xsmall-medium sm:text-small-medium mb-8 sm:mb-12 px-24 sm:px-0"
        >
          {{ productListingResources.popularCategories }}
        </div>
        <div
          class="flex"
          :class="{
            'h-[47px]': tags.length
          }"
        >
          <client-only>
            <swiper
              :modules="[Navigation]"
              :navigation="true"
              :slides-per-view="'auto'"
              :space-between="8"
              :center-insufficient-slides="true"
              :threshold="30"
              class="product-listing-filter-swiper !m-0 !px-24 sm:!px-0 max-w-full"
            >
              <swiper-slide
                v-for="(tag, index) in tags"
                :key="index"
                class="!w-auto"
              >
                <GlobalsLinkHelper
                  :to="productListingRoute(tag.urlSegment)"
                  class="btn btn--secondary btn--sm block mobileOnly:px-16 md:py-10"
                  :rel="(tag.isVirtualPage)? 'nofollow': 'follow'"
                >
                  {{ tag.name }}
                </GlobalsLinkHelper>
              </swiper-slide>
            </swiper>
          </client-only>
        </div>
        <div class="px-24 sm:px-0">
          <div
            :class="{ 'mt-24 pt-24 border-t border-grey400': tags.length }"
          >
            <button
              class="btn btn--secondary btn--sm block flex mobileOnly:px-16 md:py-10 w-full sm:w-auto border-black"
              @click="openFilter = true"
            >
              <AdjustmentsHorizontalIcon class="h-16 w-16 mr-6" />
              {{ productListingResources.filterTagButton }}
              <div
                v-if="totalFilterNumber || showRunners"
                class="bg-black rounded-full h-16 w-16 text-white relative text-tiny ml-6"
              >
                <span class="block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-px">{{ (showRunners ? (totalFilterNumber + 1) : totalFilterNumber) }}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <EpiserverContentArea
        v-if="hasTopBlocks"
        v-epi-edit="'TopContent'"
        :model="model.topContent"
        class="blockAreaTop mt-24 overflow-x-hidden"
      />
    </div>
    <client-only>
      <transition name="fade">
        <div
          v-show="hasPreviousPage && !loading"
          ref="loadMoreDiv"
          class="text-center lg:pb-32 lg:pt-56 loading"
        >
          <ArrowUpIcon
            class="h-16 w-16 inline-block arrow-animation-up"
          />
          <span class="block uppercase text-xsmall mt-4">
            {{ productListingResources.scrollAction }}
          </span>
        </div>
      </transition>
    </client-only>
    <div
      id="scrollingcontent"
      ref="scrollContainer"
      class="layout-container product-listing mb-32 sm:mb-80"
      :class="{
        'pt-32': !model.topContent
      }"
    >
      <div>
        <div
          v-for="pageData in pages"
          :id="'page-' + pageData.page"
          :key="pageData.page"
          :data-page="pageData.page"
          class="product-listing-page xsOnly:!grid-cols-1 flex-wrap grid gap-x-12 gap-y-32 mb-32 sm:mb-48 sm:grid-cols-3 md:gap-x-24 md:grid-cols-4"
          :class="[
            'items page-' + pageData.page,
            {
              'grid-cols-1 md:gap-y-24': useBigLayoutValue,
              'grid-cols-2 md:gap-y-48': !useBigLayoutValue
            }
          ]"
        >
          <div
            v-for="product in pageData.products"
            :key="product.partNo"
            class="relative"
          >
            <ProductSpot
              :product="product"
              :show-excerpt="useExcerptValue"
              :show-quickbuy="true"
              :small-layout="!isMobile ? false : true"
              :large="useBigLayoutValue"
              class="relative"
            />
          </div>
        </div>
      </div>
      <transition name="fade">
        <div
          v-show="hasNextPage && !loading"
          ref="loadMoreDiv"
          class="text-center lg:pb-32 lg:pt-56 loading"
        >
          <span class="block uppercase text-xsmall mb-4">
            {{ productListingResources.scrollAction }}
          </span>
          <client-only>
            <ArrowDownIcon
              class="h-16 w-16 inline-block arrow-animation"
            />
          </client-only>
        </div>
      </transition>
      <div class="text-xsmall sm:text-small-regular text-center mt-56 sm:mt-32">
        {{ productListingResources.showNumberOfProducts.replace('{loadedProducts}', totalLoadedProducts).replace('{totalProducts}', totalItemCount) }}
      </div>
    </div>
    <EpiserverContentArea
      v-if="hasAdditionalBlocks"
      v-epi-edit="'AdditionalContent'"
      :model="model.additionalContent"
      class="blockAreaBottom overflow-x-hidden"
    />
    <transition name="fade">
      <div
        v-if="openFilter"
        class="w-full h-full bg-overlay top-0 left-0 fixed z-mobileMenu"
        @click.self="openFilter = false"
      />
    </transition>
    <transition name="productsFilter">
      <ProductsFilter
        v-show="openFilter"
        :tags="data.tags"
        :products="data.products"
        :filtered-products-length="totalLoadedProducts"
        :sort-by="sortBy"
        :show-runners="showRunners ? showRunners : false"
        :use-big-layout-value="useBigLayoutValue ? useBigLayoutValue : false"
        :use-excerpt-value="useExcerptValue ? useExcerptValue : false"
        :listing-products-type="model.filters?.category"
        @close-filter="openFilter = false"
        @on-filter="toggleFilterSelected"
        @add-filter="addFilter"
        @on-reset-filter="resetFilters"
        @on-sort-by="setSortBy"
        @set-use-big-layout="setUseBigLayout"
        @set-excerpt-layout="setShowExcerpt"
        @set-show-runners="setShowRunners"
      />
    </transition>
    <GlobalsPagination
      class="fixed bottom-[-500px] -translate-x-1/2 left-1/2"
      :pages="totalPages"
    />
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue';
import type { ProductListingPage } from '~/content-types';
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';
import { useGlobalContentStore } from '~/store/globalContent';
import ProductSpot from '~/components/product/ProductSpot.vue';
import ProductsFilter from '~/components/product-listing-page/Filter.vue';
import ProductListingBreadcrumbs from '~/components/product-listing-page/ProductListingBreadcrumbs.vue';
import { type IProductsResult, type ITag, TagType } from '~/api-types';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import AdjustmentsHorizontalIcon from '@heroicons/vue/24/outline/esm/AdjustmentsHorizontalIcon';
import ChevronIcon from '@heroicons/vue/24/outline/esm/ChevronDownIcon';
import ArrowDownIcon from '@heroicons/vue/24/outline/esm/ArrowDownIcon';
import ArrowUpIcon from '@heroicons/vue/24/outline/esm/ArrowUpIcon';
import { useScrollStore } from '@/store/scrollStore';
import { useProductStore } from '~/store/products';
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router';

const productStore = useProductStore();
const globalContentStore = useGlobalContentStore();
const { isMobile } = storeToRefs(useUiStore());
const route = useRoute();
const router = useRouter();
const productListingResources = globalContentStore.productListingResources;
const sharedResources = globalContentStore.sharedResources;
const scrollStore = useScrollStore();

const props = defineProps<{
  model: ProductListingPage,
  isBrandpage?: boolean,
}>();

const openFilter = ref(false);
const filteredProducts = ref();
const sortBy = ref('');
const activeFilters = ref();
const useBigLayoutValue = ref();
const useExcerptValue = ref();
const tags = ref();
const showRunners = ref(false);
const pageText = ref();
const hidePageTextOverflow = ref(true);
const showPageText = ref(false);
const showToggleText = ref(true);
const totalFilterNumber = ref(0);
const searchParameters = ref('');
const hasTopBlocks = ref(false);
const hasAdditionalBlocks = ref(false);
const page = ref(parseInt(route.query.page) || 1);
let pageSize = ref(36);
const totalItemCount = ref();
const hasNextPage = ref(false);
const hasPreviousPage = ref(false);
const loadMoreDiv = ref();
const loading = ref(false);
const pages = ref();
const nextDataUrl = ref();
const prevDataUrl = ref();
const nextDataCache = ref(null);
const prevDataCache = ref(null);
const lastScroll = ref(0);
const totalPages = ref();
const totalLoadedProducts = ref();

if (props.model.showProductExcerpts) {
  useExcerptValue.value = true;
}

if (props.model.topContent) hasTopBlocks.value = true;
if (props.model.additionalContent) hasAdditionalBlocks.value = true;

scrollStore.setDisableScroll(true);

// First fetch
const { data } = await useAsyncData<IProductsResult>(
  `products${props.model.contentLink.guidValue}`,
  async() => {
    const runtimeConfig = useRuntimeConfig();
    const marketSettings = useGlobalContentStore().marketSettings;

    const initialPage = parseInt(route.query.page) || 1;
    page.value = initialPage;

    // build product request string
    let requestUrl = `${process.client ? runtimeConfig.public.apiUrl : runtimeConfig.apiUrl}products?countryCode=${marketSettings.countryCode}&language=${marketSettings.contentLanguage}&url=${route.path}&page=${page.value}&pageSize=${pageSize.value}`;

    // If page has preselected filters
    if (props.model.filters) {
      Object.entries(props.model.filters).forEach((filter) => {
        const keyWord = filter[0].charAt(0).toUpperCase() + filter[0].slice(1);
        filter[1].split(',').forEach((item: string)=> {
          if (keyWord == 'Flavors' || keyWord == 'Brands') {
            requestUrl += `&Preselected${keyWord}=${item}`;
          } else {
            requestUrl += `&${keyWord}=${item}`;
          }
        });
      });
    }

    return await $fetch(requestUrl);
  }
);

if (data.value) {
  let pagesArray = [];
  pagesArray.push({ page: page.value, products: data.value?.filteredProducts });
  pages.value = pagesArray;
  filteredProducts.value = data.value?.filteredProducts;
  totalLoadedProducts.value = filteredProducts.value.length;
  tags.value = data.value?.tags?.filter(tag => tag.type !== TagType.Flavor);
  totalItemCount.value = data.value?.totalItemCount;
  hasNextPage.value = data.value != null ? data.value?.hasNextPage : false;
  hasPreviousPage.value = data.value != null && productStore.productPages.length <= 0 ? page.value > 1 : false;
  totalPages.value = Math.ceil(totalItemCount.value / pageSize.value);
}

const handleScroll = () => {
  const scrollPos = window.scrollY;
  const scrollContainer = document.getElementById('scrollingcontent');
  const rect = scrollContainer?.getBoundingClientRect();

  if (rect && rect.bottom <= window.innerHeight - 300) {
    if (!loading.value) loadFollowing();
  }

  if (scrollPos <= 0.9 * 125 + 250 && page.value > 0) {
    if (!loading.value) loadPrevious();
  }

  if (Math.abs(scrollPos - lastScroll.value) > window.innerHeight * 0.1) {
    if (scrollPos != 0) {
      lastScroll.value = scrollPos;
    }

    document.querySelectorAll('.product-listing-page').forEach((el) => {
      if (mostlyVisible(el)) {
        const visiblePage = el.getAttribute('data-page');
        scrollStore.setDisableScroll(true);
        if (visiblePage === '1') {
          router.push(router.currentRoute.value.path + '?' + searchParameters.value);
        } else {
          if (searchParameters.value) {
            router.push(`${route.path}?'page=${visiblePage}&${searchParameters.value}`);
          } else {
            router.replace({ query: { page: visiblePage } });
          }
        }
        return false;
      }
    });
  }
};

const saveState = () => {
  productStore.setProducts(productStore.productPages);
  productStore.setScrollPosition(lastScroll.value);
  scrollStore.setDisableScroll(false);
};

onMounted( async() => {
  setPageTextOverflow();
  if (productStore.productPages && productStore.productPages.length) {
    pages.value = productStore.productPages;
    setTimeout(() => {
      window.scrollTo(0, productStore.scrollPosition);
    }, 20);
  } else if (page.value != 1) {
    scrollToProductPageContainer();
  }

  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});

onBeforeRouteLeave((to, from, next) => {
  saveState();
  next();
});

// Scroll to product container if preselected page from URL
const scrollToProductPageContainer = () => {
  const element = document.getElementById('page-' + page.value);
  const scrollValue = isMobile.value ? 115 : 160;
  if (element) {
    const offset = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: offset - scrollValue + 100,
      left: 0,
    });
  }
};

const fetchProducts = async(pageNum: number) => {
  const runtimeConfig = useRuntimeConfig();
  const marketSettings = useGlobalContentStore().marketSettings;

  let requestUrl = `${process.client ? runtimeConfig.public.apiUrl : runtimeConfig.apiUrl}products?countryCode=${marketSettings.countryCode}&language=${marketSettings.contentLanguage}&url=${route.path}&page=${pageNum}&pageSize=${pageSize.value}`;
  
  if (props.model.filters) {
    Object.entries(props.model.filters).forEach((filter) => {
      const keyWord = filter[0].charAt(0).toUpperCase() + filter[0].slice(1);
      filter[1].split(',').forEach((item: string)=> {
        requestUrl += `&${keyWord}=${item}`;
      });
    });
  }
  if (activeFilters.value) { requestUrl += activeFilters.value; }

  const data = await $fetch(requestUrl) as IProductsResult;
  return data;
};

const primePrevCache = async() => {
  if (prevDataUrl.value) {
    prevDataCache.value = await fetchProducts(prevDataUrl.value);
  }
};

const primeNextCache = async() => {
  if (nextDataUrl.value) {
    nextDataCache.value = await fetchProducts(nextDataUrl.value);
  }
};

const showFollowing = (data: any) => {
  pages.value.push({ page: data.page, products: data.filteredProducts });
  productStore.setProducts(pages.value);
  totalLoadedProducts.value = totalLoadedProducts.value + data.filteredProducts.length;
  nextDataUrl.value = hasNextPage.value ? data.page + 1 : null;
  nextDataCache.value = null;
  hasNextPage.value = data != null ? data?.hasNextPage : false;
  primeNextCache();
};

const loadFollowing = async() => {
  if (!hasNextPage.value || loading.value) return;

  if (nextDataCache.value && nextDataCache.value.page != page.value) {
    showFollowing(nextDataCache.value);
    loading.value = false;
  } else {
    if (hasNextPage.value && !loading.value) {
      loading.value = true;

      const data = await fetchProducts(page.value + 1);

      showFollowing(data);
      hasNextPage.value = data != null ? data?.hasNextPage : false;
      loading.value = false;
    }
  }
};

const showPrevious = (data: any) => {
  pages.value.unshift({ page: data.page, products: data.filteredProducts });
  productStore.setProducts(pages.value);
  totalLoadedProducts.value = totalLoadedProducts.value + data.filteredProducts.length;
  const itemHeight = document.querySelector('.product-listing-page')?.offsetHeight;
  setTimeout(() => {
    window.scrollTo(0, itemHeight + 200); // Adjust scroll
  }, 10);
  prevDataUrl.value = data.page - 1 > 0 ? data.page - 1 : null;
  prevDataCache.value = null;
  setTimeout(() => {
    loading.value = false;
  }, 2000);
  primePrevCache();
};

const loadPrevious = async() => {
  if (!hasPreviousPage.value) { return; }
  
  prevDataUrl.value = prevDataUrl.value ? prevDataUrl.value : page.value - 1;
  loading.value = true;

  if (prevDataUrl.value === 1) {
    hasPreviousPage.value = false;
  }

  if (prevDataCache.value) {
    showPrevious(prevDataCache.value);
  } else if (prevDataUrl.value && prevDataUrl.value > 0) {
    const data = await fetchProducts(prevDataUrl.value);
    showPrevious(data);
  }
};

const mostlyVisible = (element: HTMLElement ) => {
  const scrollPos = window.scrollY;
  const windowHeight = window.innerHeight;
  const elTop = element.offsetTop;
  const elHeight = element.offsetHeight;
  const elBottom = elTop + elHeight;
  return elBottom - elHeight * 0.25 > scrollPos && elTop < scrollPos + 0.5 * windowHeight;
};

const setPageTextOverflow = () => {
  hidePageTextOverflow.value = pageText.value.offsetHeight > (isMobile.value ? 70 : 110);
  showToggleText.value = pageText.value.offsetHeight > (isMobile.value ? 70 : 110);
};

const productListingRoute = ((urlSegment: string) => {
  let listingRoute = route.path;

  if (!listingRoute.endsWith('/')) {
    listingRoute += '/'; 
  }

  listingRoute += urlSegment + '/';

  return listingRoute;
});

// Fetch after filter change
const fetchFilteredProducts = (async(query: string) => {
  const runtimeConfig = useRuntimeConfig();
  const marketSettings = useGlobalContentStore().marketSettings;
  page.value = 1;
  // build product request string
  let requestUrl = `${runtimeConfig.public.apiUrl}products?countryCode=${marketSettings.countryCode}&language=${marketSettings.contentLanguage}&url=${route.path}&page=${page.value}&pageSize=${pageSize.value}`;

  // If page has preselected filters
  if (props.model.filters) {
    Object.entries(props.model.filters).forEach((filter) => {
      const keyWord = filter[0].charAt(0).toUpperCase() + filter[0].slice(1);
      filter[1].split(',').forEach((item: string)=> {
        if (keyWord == 'Flavors' || keyWord == 'Brands') {
          requestUrl += `&Preselected${keyWord}=${item}`;
        } else {
          requestUrl += `&${keyWord}=${item}`;
        }
      });
    });
  }

  // If has show runners
  if (showRunners.value) {
    requestUrl += '&Flags=senaste-produktionen';
  }

  // If has sort by filter
  if (sortBy.value) {
    requestUrl += `&sortBy=${sortBy.value}`;
  }
  
  // Add user selected filters to requestUrl
  if (query) { requestUrl += query; }

  const data = await $fetch(requestUrl) as IProductsResult;
  
  pages.value = [];
  pages.value.push({ page: data.page, products: data.filteredProducts });
  productStore.setProducts(pages.value);
  hasNextPage.value = data != null ? data?.hasNextPage : false;
  nextDataUrl.value = hasNextPage.value ? data.page + 1 : null;
  nextDataCache.value = null;
  totalLoadedProducts.value = data.filteredProducts.length;
  
  primeNextCache();
});

// Toggle select on filters
const toggleFilterSelected = ((selectedFilter: ITag, keyWord: string) => {
  data.value?.tags.forEach((tag: ITag) => {
    if (tag.name === selectedFilter.name) {
      selectedFilter.isSelected = !selectedFilter.isSelected;
    }
  });

  // Add filters to query
  addFilter(selectedFilter, keyWord);
});

const setQueryParams = async(selectedForFilter: { keyWordFilter: string, value: string}[]) => {
  const keyword = 'keyWordFilter';
  let params = [];
  searchParameters.value = '';

  // Brands
  let brandString = '';
  let brands = selectedForFilter.filter((obj) => obj[keyword] === 'Brands');
  brandString = brands.map(item => item.value).join(',');
  if (brandString) {
    params.push('Brands' + '=' + brandString);
  }

  // Strengths
  let strenghtsString = '';
  let strenghts = selectedForFilter.filter((obj) => obj[keyword] === 'Strengths');
  strenghtsString = strenghts.map(item => item.value).join(',');
  if (strenghtsString) {
    params.push('Strengths' + '=' + strenghtsString);
  }

  // Types
  let typesString = '';
  let types = selectedForFilter.filter((obj) => obj[keyword] === 'Types');
  typesString = types.map(item => item.value).join(',');
  if (typesString) {
    params.push('Types' + '=' + typesString);
  }

  // Format
  let formatsString = '';
  let formats = selectedForFilter.filter((obj) => obj[keyword] === 'Formats');
  formatsString = formats.map(item => item.value).join(',');
  if (formatsString) {
    params.push('Formats' + '=' + formatsString);
  }

  // Flavor
  let flavorsString = '';
  let flavors = selectedForFilter.filter((obj) => obj[keyword] === 'Flavors');
  flavorsString = flavors.map(item => item.value).join(',');
  if (flavorsString) {
    params.push('Flavors' + '=' + flavorsString);
  }

  if (params.length) searchParameters.value = params.join('&');

  const showRunnersQuery = showRunners.value ? '&ShowRunners=true' : '';
  const sortByQuery = sortBy.value ? '&SortBy=' + sortBy.value : '';
  await router.push(route.path + '?' + searchParameters.value + showRunnersQuery + sortByQuery);
};

let selectedForFilter: { keyWordFilter: string, value: string}[] = [];

// Add user selected filters
const addFilter = ((value: ITag, keyWord: string) => {
  let query = '' as string;

  if (value.isSelected) {
    selectedForFilter.push({ keyWordFilter: keyWord, value: value.urlSegment });
  } else if (!value.isSelected) {
    // Remove object from filter array when not checked
    selectedForFilter = selectedForFilter.filter((obj) => {
      return obj.value !== value.urlSegment;
    });
  }

  selectedForFilter.forEach((item: {keyWordFilter: string, value: string})=> {
    query += `&${item.keyWordFilter}=${item.value}`;
  });
  setQueryParams(selectedForFilter);
  activeFilters.value = query;
  totalFilterNumber.value = selectedForFilter.length;
  
  fetchFilteredProducts(activeFilters.value);
});

const resetFilters = (() => {
  totalFilterNumber.value = 0;
  data.value?.tags.forEach((value: ITag) => {
    value.isSelected = false;
  });
  selectedForFilter = [];
  localStorage.removeItem('useBigLayout');
  localStorage.removeItem('showExcerpt');
  showRunners.value = false;
  router.push(route.path);
  fetchFilteredProducts('');
  openFilter.value = false;
});

const setSortBy = ((value: string) => {
  sortBy.value = value;
  const sortByQuery = sortBy.value ? '&SortBy=' + sortBy.value : '';
  const showRunnersQuery = showRunners.value ? '&ShowRunners=true' : '';
  router.push(route.path + '?' + searchParameters.value + showRunnersQuery + sortByQuery);

  fetchFilteredProducts(activeFilters.value);
});

const setShowRunners = ((value: boolean) => {
  showRunners.value = value;
  const showRunnersQuery = showRunners.value ? '&ShowRunners=true' : '';
  const sortByQuery = sortBy.value ? '&SortBy=' + sortBy.value : '';
  router.push(route.path + '?' + searchParameters.value + showRunnersQuery + sortByQuery);

  fetchFilteredProducts(activeFilters.value);
});

const setUseBigLayout = (() => {
  useBigLayoutValue.value = !useBigLayoutValue.value;
});

const setShowExcerpt = (() => {
  useExcerptValue.value = !useExcerptValue.value;
});

const queryBrands = ref(route.query.Brands);
const queryFormats = ref(route.query.Formats);
const queryTypes = ref(route.query.Types);
const queryFlavors = ref(route.query.Flavors);
const queryStrengths = ref(route.query.Strengths);
const queryShowRunners = ref(route.query.ShowRunners);
const querySortBy = ref(route.query.SortBy);

// Filter products if has query params
const checkQuery = () => {
  let queryString = '';
  let counter = 0;

  if (queryBrands.value) {
    queryBrands.value.split(',').forEach((item: string)=> {
      queryString += `&Brands=${item}`;
      counter++;
    });
  }

  if (queryFormats.value) {
    queryFormats.value.split(',').forEach((item: string)=> {
      queryString += `&Formats=${item}`;
      counter++;
    });
  }

  if (queryTypes.value) {
    queryTypes.value.split(',').forEach((item: string)=> {
      queryString += `&Types=${item}`;
      counter++;
    });
  }

  if (queryFlavors.value) {
    queryFlavors.value.split(',').forEach((item: string)=> {
      queryString += `&Flavors=${item}`;
      counter++;
    });
  }

  if (queryStrengths.value) {
    queryStrengths.value.split(',').forEach((item: string)=> {
      queryString += `&Strengths=${item}`;
      counter++;
    });
  }

  if (queryShowRunners.value) {
    showRunners.value = true;
    queryString += '&ShowRunners=true';
    counter++;
  }

  if (querySortBy.value) {
    sortBy.value = querySortBy.value;
    queryString += `&sortBy=${sortBy.value}`;
  }

  totalFilterNumber.value = counter;

  if (queryString) {
    fetchFilteredProducts(queryString);
  }

};

checkQuery();

watch(route, () => {
  saveState();
});

const jsonLd = {
  '@context': 'http://schema.org',
  '@type': 'CollectionPage',
  name: props.model?.name,
  mainEntity: {
    '@type': 'ItemList',
    itemListElement: data.value?.products.map((item) => {
      return {
        '@type': 'ListItem',
        name: item.name,
        description: item.shortDescription ? item.shortDescription : item.description,
        url: item.url,
        
      };
    }),
  },
};

if (props.isBrandpage) {
  useHead({
    script: [
      {
        type: 'application/ld+json',
        innerHTML: JSON.stringify(jsonLd),
      },
    ],
  });
}

let canonical = route.path;
if (route.query.page) {
  canonical = `${route.path}?page=${route.query.page}`;
}

useHead({
  link: [
    {
      rel: 'canonical',
      href: canonical,
    },
  ],
});
</script>
<style lang="postcss" scoped>
.page-text {
  &--hide-overflow {
    @apply max-h-[74px] sm:max-h-[100px] overflow-hidden relative pb-24 sm:pb-32 mb-32;

    &::after {
      content: '';
      background-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 40%,
        rgba(255, 255, 255, 0) 100%
      );
      height: 80%;
      background-color: rgba(255, 255, 255, 0);
      @apply absolute bottom-0 left-0 w-full pointer-events-none;
    }

    &.page-text--show {
      @apply max-h-full;
      &::after {
        @apply opacity-0;
      }
    }
  }

  .rotate-x-180 {
    transform: rotateX(180deg);
  }
}
</style>

<style lang="postcss">
.productsFilter-enter-active {
  transition: all 0.4s ease-out;
}
.productsFilter-leave-active {
  transition: all 0.4s ease-in;
}

.productsFilter-enter-from,
.productsFilter-leave-to {
  transform: translateY(100%);
  @screen md {
    transform: translateX(-100%); 
  }
}

.variant {
  &.checkbox-checked {
    label {
      @apply text-grey600;
    }

    .checkbox-container input:checked ~ .checkmark {
      @apply border-grey600 bg-grey600;
    }
  }
}

input[type='radio'] {
  &:checked {
    @apply bg-black border-black;
  }
}

input[type='checkbox'] {
  &:checked::before {
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: '';
  }
}

input[type='radio'] {
  @apply rounded-full;
  &:checked::before {
    @apply bg-white h-6 w-6 rounded-full;

    content: '';
  }
}

.arrow-animation {
  transition: all 0.5s ease-in-out;
  animation: scrollDown ease-in-out 1.5s infinite;
}

@keyframes scrollDown {
  0% {
    transform: translateY(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}

.arrow-animation-up {
  transition: all 0.5s ease-in-out;
  animation: scrollUp ease-in-out 1.5s infinite;
}

@keyframes scrollUp {
  0% {
    transform: translateY(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}
</style>
