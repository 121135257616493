import { defineStore } from 'pinia';
import type { IProduct } from '~/api-types';

export const useProductStore = defineStore('productStore', {
  state: () => ({
    productPages: [] as Array<{ page: number; products: IProduct[] }>,
    scrollPosition: 0,
  }),
  actions: {
    setProducts(pages: Array<{ page: number; products: IProduct[] }>) {
      this.productPages = pages;
    },
    setScrollPosition(position: number) {
      this.scrollPosition = position;
    },
  },
});
